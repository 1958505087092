import CitySelector
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv/domestic-ticket-airport-selector/1.2.2/index.vue";
import FlightListSkeleton from '@/component/skeleton/flight-list-skeleton/index.vue'
import consumer_flight_search_changeSearchV2 from '@/lib/data-service/haolv-default/consumer_flight_search_changeSearchV2';
import consumer_flight_search_changeSearch from "@/lib/data-service/haolv-default/consumer_flight_search_changeSearch";
import consumer_flight_search_stipulatesRule from "@/lib/common-service/consumer_flight_search_stipulatesRule";
import consumer_flight_search_changeFlightRule from '@/lib/data-service/haolv-default/consumer_flight_search_changeFlightRule';
import ChangeClausePopup from "@/page/admin/travel-book/flight/component/changeClausePopup/1.0.0/index.vue";

export default {
    data() {
        return {
            loades: 10,
            loadingShow: true,
            formData: {
                depCityPort: {},
                arrCityPort: {},
                orderField: '', // 排序字段，depTime-起飞时间 arrTime-到达时间。basePrice-价格排序 默认按起飞时间
                orderType: '', // 排序类型，asc-从小到大/从早到晚/从低到高 desc-从大到小/从晚到早/从高到低。默认asc
                "queryDate": "",
                "orderNo": "",
                "segmentNos": [],
                "segNos": [],
                "reasonCode": ''
            },
            startPickerOptions: {
                disabledDate: time => {
                    return time.getTime() < Date.now() - 1000 * 3600 * 24;
                }
            },
            endPickerOptions: {
                disabledDate: time => {
                    if (this.formData.queryDate) {
                        return time.getTime() < new Date(this.formData.queryDate).valueOf();
                    } else {
                        return time.getTime() < Date.now() - 1000 * 3600 * 24;
                    }
                }
            },

            flightsList: [],

            ruleShow: false, // 退改规则弹窗
            ruleText: {},
            ruleLoading: false,
            canJourney: true,
            flightType: 1,
            productTag: 0,
            errMsg: '',
            remarkMsg: '',
        }
    },
    props: {
        changeType: {
            type: Number
        },
        changeForm: {
            type: Object
        },
        evectionNo: {
            type: String
        },
        changeOrderNo: {
            type: String
        },
        changeInfo: {
            type: Object,
            default: ()=>{}
        }
    },
    components: {ChangeClausePopup, FlightListSkeleton, CitySelector},
    created() {
    },
    mounted() {
        this.formData = Object.assign(this.formData, this.changeForm)
        this.reactSearch()
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterPlaneSize(val) {
            if (val === 1) {
                return '大型机'
            } else if (val === 2) {
                return '中型机'
            } else if (val === 3) {
                return '小型机'
            } else {
                return ''
            }
        },
    },
    methods: {
        init() {

        },
        reactSearch() {
            this.search()
        },
        search() {
            this.loadingShow = true;
            this.flightsList = [];
            let formData = JSON.parse(JSON.stringify(this.formData));
            delete formData.depCityPort;
            delete formData.arrCityPort;
            delete formData.orderField;
            delete formData.orderType;
            consumer_flight_search_changeSearchV2(formData).then(res=>{
                this.remarkMsg = '';
                this.errMsg = res.msg;
                this.loadingShow = false;
                if (!res.changeFlightList) {
                    return
                }
                let changeFlightList = res.changeFlightList;
                changeFlightList.forEach(value=>{
                    this.remarkMsg = value.remarkMsg;
                    value.show = 0
                })
                this.flightsList = changeFlightList;
                this.sortList();
            }).catch(e=>{
                this.loadingShow = false;
                this.flightsList = [];
                this.remarkMsg = '';
                this.errMsg = e.data.msg;
            });
        },
        sortSwitch(orderField) {
            if (this.formData.orderField === orderField) {
                // 如果本来就是起飞时间排序
                let sortArr = ['asc', 'desc', '']
                let sortIndex = sortArr.indexOf(this.formData.orderType)
                sortIndex === 2 ? sortIndex = 0 : sortIndex++
                this.formData.orderType = sortArr[sortIndex]
            } else {
                // 如果不是起飞时间排序
                this.formData.orderField = orderField
                this.formData.orderType = 'asc'
            }
            this.sortList()
        },
        sortList() {
            let flightList = JSON.parse(JSON.stringify(this.flightsList))

            let timeToMinute = function (val) {
                let timeVal = val.split(':')
                let hour = parseInt(timeVal[0]) * 60
                let minute = parseInt(timeVal[1])
                return hour + minute
            }
            // 按出发时间正序排列
            let depTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按出发时间反序排列
            let depTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按到达时间正序排列
            let arrTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按到达时间反序排列
            let arrTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }

            if (this.formData.orderField === 'depTime') {
                if (this.formData.orderType === 'asc') {
                    flightList.sort(depTimeCompare)
                } else if (this.formData.orderType === 'desc') {
                    flightList.sort(depTimeReverseCompare)
                }
            } else if (this.formData.orderField === 'arrTime') {
                if (this.formData.orderType === 'asc') {
                    flightList.sort(arrTimeCompare)
                } else if (this.formData.orderType === 'desc') {
                    flightList.sort(arrTimeReverseCompare)
                }
            }
            this.loadingShow = false
            this.flightsList = flightList
        },
        reloadItem(item, index) {
            item.show === 0 ? item.show = 1 : item.show = 0
        },
        getStopMsg() {},
        showChangeClause(policyItem, item) {
            // 如果是自营的话，即supplierType = 7， ruleText返回空，需要调接口获取退改规则和行李额
            // 如果是非自营，即supplierType ！= 7， ruleText返回退改规则，需要调接口获取行李额
            this.ruleShow = true
            if (policyItem.ruleText) {
                this.ruleText = policyItem.ruleText;
            }
            this.ruleLoading = true
            let params = {
                "supplierType": item.supplierType,
                "flightNo": item.flightNo,
                "cabinCode": policyItem.cabinCode,
                "depAirPortCode": item.depAirPortCode,
                "arrAirPortCode": item.arrAirPortCode,
                "depCityCode": item.depCityCode,
                "arrCityCode": item.arrCityCode,
                "depDate": item.depDate,
                "depTime": item.depTime,
                "psgType": 1,
                "shareFlightNo": item.sharedFlightNo || '',
                "price":policyItem.price,
                "settlement":policyItem.price
            }
            consumer_flight_search_changeFlightRule(params).then(res=>{
                let rule = res.rsData;
                if (policyItem.ruleText) {
                    this.ruleText.flyFree = rule.flyFree;
                    this.ruleText.freeWithYou = rule.freeWithYou;
                } else {
                    this.ruleText = rule
                }
            }).finally(() => {
                this.ruleLoading = false
            });
            /*consumer_flight_search_stipulatesRule(params).then(res => {
                if (this.changeInfo.policyType === '61') {
                    // 自营
                    this.ruleText = res.datas.rsData
                } else {
                    this.ruleText.flyFree = res.datas.rsData.flyFree
                    this.ruleText.freeWithYou = res.datas.rsData.freeWithYou
                    this.ruleText.luggage = res.datas.rsData.luggage
                }

            }).finally(() => {
                this.ruleLoading = false
            })*/
            /**/
        },
        getPrice() {},
        async journeyType(policyItem, item) {
            if (!this.canJourney) {
                return
            }
            this.canJourney = false
            let formData = {
                rqData: {
                    "evectionNo": this.evectionNo,
                    "journeyNo": this.changeOrderNo,
                    cabinRank: this.changeForm.cabinRank,
                    directFlight: 0,
                    flightType: this.formData.flightType,
                    psgType: this.psgType,
                    segmentList: []
                }
            };
            let segment = {
                "airportTax": policyItem.airportTax,
                "billingFee": policyItem.billingFee,
                "cabinName": policyItem.cabinName,
                "cabinCode": policyItem.cabinCode,
                "cabinRank": policyItem.cabinRank,
                "chdCabinCode": policyItem.chdCabinCode,
                "comment": policyItem.comment,
                "crewType": policyItem.crewType,
                "discount": policyItem.discount,
                "fuelTax": policyItem.fuelTax,
                "policyId": policyItem.policyId,
                "price": policyItem.price,
                "priceTaxes": policyItem.priceTaxes,
                "productType": policyItem.productType,
                "segmentType": 1,//去返程
                "settlement": policyItem.settlement,
                "settlementPriceTaxes": policyItem.settlementPriceTaxes,
                "vtWorkTime": policyItem.vtWorkTime,
                "workTime": policyItem.workTime,
                "depTime": item.depTime,
                "flightProductType": policyItem.flightProductType,
                businessExt: policyItem.businessExt,
                "productTag": policyItem.productTag,
                rule: policyItem.rule,
                ruleText: policyItem.ruleText,

                "airCompanyCode": item.airCompanyCode,
                "airCompanyName": item.airCompanyName,
                "arrAirportCode": item.arrAirportCode,
                "arrAirportName": item.arrAirportName,
                "arrAirPortCode": item.arrAirportCode,
                "arrAirPortName": item.arrAirportName,
                "arrCityCode": item.arrCityCode,
                "arrCityName": item.arrCityName,
                "arrDate": item.arrDate,
                "arrTerminal": item.arrTerminal,
                "arrTime": item.arrTime,
                "chaRule": item.chaRule,
                "depAirportCode": item.depAirportCode,
                "depAirportName": item.depAirportName,
                "depAirPortCode": item.depAirportCode,
                "depAirPortName": item.depAirportName,
                "depCityCode": item.depCityCode,
                "depCityName": item.depCityName,
                "depDate": item.depDate,
                "depTerminal": item.depTerminal,
                "directFlight": item.directFlight,
                "duration": item.duration,
                "flightNo": item.flightNo,
                "planeType": item.planeType,
                "refRule": item.refRule,
                "stopNum": item.stopNum,

                policyType: policyItem.policyType,
                hasShare: item.hasShare,
                isShare: item.hasShare ? 1 : 0, // 是否共享航班 1-是 0-否"
                sharedFlightNo: item.sharedFlightNo,
                sharedAirCompanyCode: item.sharedAirCompanyCode,
                isShareText: `实际乘坐航班：${item.sharedAirCompanyName}${item.sharedFlightNo}请按实际乘坐航班办理值机。`,

                flightKey: item.flightKey,
                supplierType: item.supplierType,
            }
            /**单程 */
            segment.segmentType = 1
            formData.rqData.segmentList.push(Object.assign({}, segment))
            this.params = formData
            console.log(this.params)
            // 因私出差
            // if (this.evectionType === 1) {
            //     this.params.rqData.overproofApplyList = []
            //     this.reserveOrder()
            // } else {
            //     this.$refs.checkTravelBox.run()
            // }
            this.params.rqData.overproofApplyList = [];
            this.reserveOrder()
        },
        reserveOrder() {
            let obj = {
                violationResults: [],
                params: this.params
            }
            this.$emit('getSegment', obj)
            this.canJourney = true
        },
    }
}
