import ChangClause from "@/page/admin/travel-book/flight/component/changClause";
import consumer_flight_search_stipulatesRule from '@/lib/data-service/haolv-default/consumer_flight_search_stipulatesRule'
import ChangeClausePopup from '@/page/admin/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
import consumer_flight_search_changeFlightRule
    from "@/lib/data-service/haolv-default/consumer_flight_search_changeFlightRule";
export default {
    data() {
        return {
            ruleShow: false, // 退改规则弹窗
            ruleText: {},
            ruleLoading: false,
        };
    },
    props: {
        detail: Array,
        type: String,
        ticketDetail: Object
    },
    components: {
        ChangClause,
        ChangeClausePopup
    },
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        //退改规则
        backRule(val, index) {
            console.log('-----');
            console.log(val);
            this.ruleShow = true
            if (val.ruleText) {
                this.ruleText = val.ruleText;
            }
            this.ruleLoading = true
            let params = {
                "supplierType": val.supplierType,
                "flightNo": val.flightNo,
                "cabinCode": val.cabinCode,
                "depAirPortCode": val.depAirPortCode,
                "arrAirPortCode": val.arrAirPortCode,
                "depCityCode": val.depCityCode,
                "arrCityCode": val.arrCityCode,
                "depDate": val.depDate,
                "depTime": val.depTime,
                "psgType": 1,
                "shareFlightNo": val.sharedFlightNo || '',
                "price":val.price,
                "settlement":val.price
            }
            consumer_flight_search_changeFlightRule(params).then(res=>{
                let rule = res.rsData;
                if (val.ruleText) {
                    this.ruleText.flyFree = rule.flyFree;
                    this.ruleText.freeWithYou = rule.freeWithYou;
                } else {
                    this.ruleText = rule
                }
            }).finally(() => {
                this.ruleLoading = false
            });
            /*consumer_flight_search_stipulatesRule(params).then(res => {
                if (this.ticketDetail.policyType === '61') {
                    this.ruleText = res.datas.rsData
                } else {
                    this.ruleText.flyFree = res.datas.rsData.flyFree
                    this.ruleText.freeWithYou = res.datas.rsData.freeWithYou
                    this.ruleText.luggage = res.datas.rsData.luggage
                }
            }).finally(() => {
                this.ruleLoading = false
            })*/
        },
        noShow() {
            this.changeShow = null;
        }
    }
}
