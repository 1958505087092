// consumer_flight_search_changeFlightRule
const __request = require(`./__request/__request_contentType_json`);
const consumer_flight_search_changeFlightRule = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/flight/search/changeFlightRule',
        data: pParameter,
        enable_error_alert: false // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_flight_search_changeFlightRule;
